import { css } from "@emotion/react";

export const container = css`
    display: flex;
    width: 100%;
    height: 750px;
    flex-wrap: nowrap;
    justify-content: center;
`
export const mapContainer = css`
    height: 100%;
`

export const mapImg = css`
    height: 100%;
`

export const tableContainer = css`
    padding-top: 10px;
    margin-left: 20px;
    width: 480px;
`

export const textDataContainer = css`
    padding: 40px 0px;

`
export const header = css`
    height: 40px;
    font-size: 20px;
`

export const content = css`
    font-size: 14px;
    padding: 0px 10px;
    line-height: 1.5;
`

export const mapLinkContainer = css`
    display: flex;
    flex-direction: column;
    align-items: end;

`
    
export const mapLinkText = css`
    font-size: 12px;
    margin-bottom: 10px;
`

export const mapLinkCategoryName = css`
    font-weight: 700;
    color: #002755;
`

export const mapLinkButton = css`
    font-size: 15px;
    height: 30px;
    width: 150px;
    background-color: #002755;
    color: white;
    border-radius: 12px;
    white-space: nowrap;
    cursor: pointer;
`

