import { css } from "@emotion/react"

export const mainContainer = css`
    padding-top: 20px;
    padding-bottom: 5px;
`

export const header = css`
    font-size: 18px;
    padding: 5px 0px;
`

export const sliderInputContainer = css`
    display: flex;
    align-items: center;

    input {
        ::-webkit-outer-spin-button {
        -webkit-appearance: none;
    }

        ::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }
    }
`

export const sliderInputName = css`
    width: 60px;
    padding: 5px 0px;
    font-size: 16px;
`