import { css } from "@emotion/react"

export const mainContainer = css`
    position: relative;
`

export const button = css`

`

export const embedContainer = (isOpen) => css`
    display: ${isOpen ? "flex" : "none"};
    flex-direction: column;
    position: absolute;

    width: 300px;

    font-size: 13px;

    z-index: 10;
    right: 0px;
    background-color: #fff;
    border: 1px solid #dbdbdb;
    padding: 8px;
`

export const header = css`
    font-size: 16px;
    font-weight: 700;
    margin: 5px 0px;
`

export const paramContainer = css`
    padding-bottom: 8px;
    margin-bottom: 6px;
    border-bottom: 1px solid #dbdbdb;
`

export const input = css`
    ::-webkit-outer-spin-button {
        -webkit-appearance: none;
    }

    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    margin: 2px 2px;
`

export const createButton = css`
    width: 100%;
    margin-top: 5px;
`

export const urlText = css`
    padding: 1px;
    height: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

export const copyUrlContainer = css`
    width: 100%;
`

export const urlContainer = css`
    padding: 2px;
    border: 1px solid #dbdbdb;
    margin: 5px 0px;
`

export const copyButton = css`
    width: 100%;
`

export const modalContainer = (isOpen) => css`
    display: ${isOpen ? "flex" : "none"};
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 999;

    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    background-color: #000000aa;;
`
