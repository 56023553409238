/** @jsxImportSource @emotion/react */
import * as s from "./style"
import React, { useEffect, useState } from 'react';

const MIN_WIDTH = 500;
const MAX_WIDTH = 1150;
const MIN_HEIGHT = 300
const MAX_HEIGHT = 850;

const SetSize = ({ size, setSize }) => {
    const [ sizeInputValue, setSizeInputValue ] = useState({ width: 1000, height: 700 });

    useEffect(() => {
        if(size !== sizeInputValue) {
            setSizeInputValue(size);
        }
    }, [size])

    const onChangeSizeHandle = (e) => {
        const { name, value } = e.target;

        if(name === "width") {
            if(value > MAX_WIDTH) {
                setSizeInputValue({
                    ...sizeInputValue,
                    [name]: MAX_WIDTH
                })
            } else {
                setSizeInputValue({
                    ...sizeInputValue,
                    [name]: value
                })
            }
        } else if(name ==="height") {
            if (value > MAX_HEIGHT) {
                setSizeInputValue({
                    ...sizeInputValue,
                    [name]: MAX_HEIGHT
                })
            } else {
                setSizeInputValue({
                    ...sizeInputValue,
                    [name]: value
                })
            }
        }

    }

    const onClickSizeSaveButton = () => {
        let { width, height } = sizeInputValue;

        if(width > MAX_WIDTH) {
            width = MAX_WIDTH
        } else if(width < MIN_WIDTH) {
            width = MIN_WIDTH
        }

        if(height > MAX_HEIGHT) {
            height = MAX_HEIGHT
        } else if(height < MIN_HEIGHT) {
            height = MIN_HEIGHT
        }
        
        setSize({
            width: width,
            height: height
        })
    }

    return (
        <div css={s.mainContainer}>
            <header css={s.header}>지도크기 설정</header>
            <div css={s.sliderInputContainer}>
                <span css={s.sliderInputName}>width: </span>
                <input name={"width"} type="range" min={MIN_WIDTH} max={MAX_WIDTH} value={sizeInputValue.width} onChange={onChangeSizeHandle}/>
                <input name={"width"} type="number" min={MIN_WIDTH} max={MAX_WIDTH} value={sizeInputValue.width} onChange={onChangeSizeHandle}/>
            </div>
            <div css={s.sliderInputContainer}>
                <span css={s.sliderInputName}>height: </span>
                <input name={"height"} type="range" min={MIN_HEIGHT} max={MAX_HEIGHT} value={sizeInputValue.height} onChange={onChangeSizeHandle}/>
                <input name={"height"} type="number" min={MIN_HEIGHT} max={MAX_HEIGHT} value={sizeInputValue.height} onChange={onChangeSizeHandle}/>
            </div>
            <button onClick={onClickSizeSaveButton}>크기변경완료</button>
        </div>
    );
};

export default SetSize;