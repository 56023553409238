import { css } from "@emotion/react";

export const mainContainer = css`
    display: flex;
    flex-direction: row;
    background-color: white;

    width: 1620px;
    height: 870px;

    border-radius: 8px;
`

export const thumbContainer = css`
    display: flex;
    box-sizing: border-box;
    width: 1170px;
    height: 870px;
    padding: 10px;
    background-color: #999999;

    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;

    justify-content: center;
    align-items: center;
`

export const optionContainer = css`
    display: flex;
    width: 450px;
    flex-direction: column;
    padding: 10px;
`

export const optionHeader = css`
    font-size: 20px;
    font-weight: 700;
`