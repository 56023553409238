/** @jsxImportSource @emotion/react */
import { Switch } from "@mui/material";
import * as s from "./style"
import React, { useEffect, useState } from 'react';
import axios from "axios";
import { API_URL } from "../../../../../config/URL/URL";

const SetGraph = ({ code, properties, setProperties }) => {
    const [ isActive, setIsActive ] = useState(false);

    useEffect(() => {
        if(properties) {
            const newProperties = {
                ...properties,
                overlay: {
                    ...properties.overlay,
                    graph: {
                        ...properties.overlay.graph,
                        isActive: isActive,
                    }
                }
            }

            updateProperties(newProperties);
        }
    }, [isActive])

    const updateProperties = async (properties) => {
        const option = {
            headers: {
                "Content-Type": "application/json"
            }
        }

        const data = {
            code: code,
            properties: JSON.stringify(properties)
        }

        try {
            const response = await axios.post(`${API_URL}/embed/change`, data, option)

            setProperties(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    const onClickActiveButtonHandle = () => {
        setIsActive(!isActive);
    }

    return (
        <div css={s.mainContainer}>
            <header css={s.header}>
                <span>그래프</span>
                <Switch value={isActive} onClick={onClickActiveButtonHandle}/>
            </header>
        </div>
    );
};

export default SetGraph;