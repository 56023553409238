/** @jsxImportSource @emotion/react */
import * as s from "./style"
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { API_URL } from '../../../config/URL/URL';
import DetailModal from './detail_modal/DetailModal';

const EmbedButton = ({ selectedCategory, selectedYear, selectedSido, selectedSgg, view, map }) => {
    const copyRef = useRef();
    const [ isOpen, setIsOpen ] = useState(false);
    const [ isModalOpen, setIsModalOpen] = useState(false);

    const [ url, setUrl ] = useState("");

    const modalRef = useRef();

    const [ size, setSize ] = useState({
        width: 700,
        height: 500,
    })

    const [ embedCode, setEmbedCode ] = useState()

    const createEmbed = async () => {
        const option = {
            headers: {
                "Content-Type": "application/json"
            }
        }

        const properties = {
            selectedCategory: selectedCategory,
            selectedYear: selectedYear,
            selectedSido: selectedSido,
            selectedSgg: selectedSgg,
            view: {
                center: view.getCenter(),
                zoom: view.getZoom()
                
            },
            overlay: {
                year: {
                    isActive: false,
                    shapes: null
                },
                table: {
                    isActive: false,
                    shapes: null
                },
                graph: {
                    isActive: false,
                    shapes: null
                }

            }
        }

        const data = {
            properties: JSON.stringify(properties)
        }

        try {
            const response = await axios.post(`${API_URL}/embed/create`, data, option);
            return response.data;

        } catch (error) {
            console.log(error)
        }
    }

    const onClickButtonHandle = () => {
        setIsOpen(!isOpen);
    }

    const onClickCreateButtonHandle = async () => {
        const code = await createEmbed();
        setUrl(`<embed src=https://ypt.kr:7005/${code} width="${size.width}px" height="${size.height}px" />`)
    }

    const onClickCopyButtonHandle = () => {
        navigator.clipboard.writeText(copyRef.current.innerText)
            .then(() => {
                alert("코드가 클립보드에 복사되었습니다.");
            })
            .catch((error) => {
                alert("클립보드 복사에 실패하였습니다.")
            })
    }

    const onChangeInputHandle = (e) => {
        const { name, value } = e.target;
        setSize({...size, [name]: Number(value)});
    }

    const onClickModalOpenButton = async () => {
        const code = await createEmbed();
        setEmbedCode(code);
        setIsModalOpen(true);
    }

    const onClickModalOutsideHandle = (e) => {
        if(modalRef.current && !modalRef.current.contains(e.target)) {
            setIsModalOpen(false)
        }
    }

    return (
        <div css={s.mainContainer}>
            <button onClick={onClickButtonHandle}>
                내보내기
            </button>
            <div css={s.embedContainer(isOpen)}>
                <div css={s.copyUrlContainer}>
                    <header css={s.header}>
                        지도 공유
                    </header>
                    <div css={s.paramContainer}>
                        <div>
                            <span>width: </span>
                            <input css={s.input} name={"width"} type="number" min={100} max={1000} value={size.width} onChange={onChangeInputHandle}/>
                            <span>px</span>
                        </div>
                        <div>
                            <span>height: </span>
                            <input css={s.input} name={"height"} type="number" min={100} max={800} value={size.height} onChange={onChangeInputHandle}/>
                            <span>px</span>
                        </div>
                        <button onClick={onClickModalOpenButton}>상세설정</button>
                        <button css={s.createButton} onClick={onClickCreateButtonHandle}>생성</button>
                    </div>
                    <div css={s.urlContainer}>
                        <p css={s.urlText} ref={copyRef}>{url}</p>
                    </div>
                    <button css={s.copyButton} onClick={onClickCopyButtonHandle}>복사</button>
                </div>
                <div css={s.modalContainer(isModalOpen)} onClick={onClickModalOutsideHandle}>
                    <DetailModal code={embedCode} modalRef={modalRef}/>
                </div>
            </div>
        </div>
    );
};

export default EmbedButton;