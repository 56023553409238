import { css } from '@emotion/react';

export const container = (isRoot) => css`
    display: flex;
    justify-content: space-between;
    width: 1170px;
    height: 80px;
    padding-top: 25px;
    background-color: ${isRoot ? '#002755' : '#fdfdfd'};
`
export const logo = css`
    display: flex;
    height: 25px;
`

export const line = css`
    width: 2px;
    background-color: lightgray;
    margin: 0 15px;
`;

export const copyright = (isRoot) => css`
    color: ${isRoot ? '#fdfdfd' : 'black'};
    text-align: end;
    font-size: 10px;
    line-height: 1.5;
`