import { css } from "@emotion/react";

export const mainContainer = css`
    padding: 10px 0px;
`

export const header = css`
    display: flex;
    font-size: 18px;
    padding: 5px 0px;
    justify-content: space-between;
    align-items: center;
`

export const sliderInputContainer = css`
    display: flex;
    align-items: center;

    input {
        ::-webkit-outer-spin-button {
        -webkit-appearance: none;
    }

        ::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }
    }
`

export const sliderInputName = css`
    width: 60px;
    padding: 5px 0px;
    font-size: 16px;
`