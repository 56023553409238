/** @jsxImportSource @emotion/react */
import axios from "axios";
import SetSize from "./set_size/SetSize";
import SetYear from "./set_year/SetYear";
import * as s from "./style"
import React, { useEffect, useState } from 'react';
import { API_URL } from "../../../../config/URL/URL";
import SetTable from "./set_table/SetTable";
import SetGraph from "./set_graph/SetGraph";

const DetailModal = ({ code, modalRef }) => {
    const [ size, setSize ] = useState({ width: 1000, height: 700 });
    const [ properties, setProperties ] = useState();

    useEffect(() => {
        if(code) {
            getProperties()
        }
    }, [code])

    const getProperties = async () => {
        const option = {
            params: {
                code: code
            }
        }

        try {
            const response = await axios.get(`${API_URL}/embed/get`, option)
            setProperties(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    const changeProperties = async () => {
        const option = {
            headers: {
                "Content-Type": "application/json"
            }
        }

        const data = {
            code: code,
            properties: JSON.stringify(properties)
        }

        try {
            const response = await axios.post(`${API_URL}/embed/change`, data, option)
            setProperties(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    const onClickSaveButtonHandle = () => {
        changeProperties()
    }

    return (
        <div css={s.mainContainer} ref={modalRef}>
            <div css={s.thumbContainer}>
                <embed key={JSON.stringify(properties)} src={`http://localhost:3001/modify/${code}`} width={`${size.width}px`} height={`${size.height}px`} />
            </div>
            <div css={s.optionContainer}>
                <header css={s.optionHeader}>지도 설정</header>
                <div>
                    <SetSize size={size} setSize={setSize}/>
                    <SetYear code={code} properties={properties} setProperties={setProperties} />
                    <SetTable code={code} properties={properties} setProperties={setProperties} />
                    <SetGraph code={code} properties={properties} setProperties={setProperties} />
                </div>
                <button onClick={onClickSaveButtonHandle}>저장</button>
            </div>


        </div>
    );
};

export default DetailModal;
