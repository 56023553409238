import { css } from "@emotion/react";

export const mainContainer = css`
    padding: 10px 0px;
`

export const header = css`
    display: flex;
    font-size: 18px;
    padding: 5px 0px;
    justify-content: space-between;
    align-items: center;
`
